import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import store from '@s/store';
import { apiUrl } from '@l/api';
import { FailureResponse, get, post } from '@l/axios-helper';
import { System } from '@i/system';
import { AxiosError } from 'axios';

@Module({ dynamic: true, store, name: 'system', namespaced: true })
class SystemStore extends VuexModule {
    errorMessage = '';
    systemList: System[] = [];
    encryptParam = '';

    @Action({ rawError: true })
    public async addSystem(args: { systemName: string; identifier: string }): Promise<boolean> {
        try {
            const response = await post(`${apiUrl}/system`, args);
            this.getSystem();

            return response.data.code === '0000' ? true : false;
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                this.setErrorMessage(err.response?.data.message ?? '');
                console.log(`failed to add system`);
            }
        }

        return false;
    }

    @Action({ rawError: true })
    public async encrypt(args: { systemId: number; parameter: string }): Promise<boolean> {
        try {
            const response = await post(`${apiUrl}/system/encrypt`, args);

            this.setEncrypt(response.data.data.encrypted);
            return response.data.code === '0000' ? true : false;
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                this.setErrorMessage(err.response?.data.message ?? '');
                console.log(`failed to add system`);
            }
        }

        return false;
    }

    @Action({ commit: 'setSystemList', rawError: true })
    public async getSystem(): Promise<System[]> {
        try {
            const response = await get(`${apiUrl}/system`);

            return response.data.data.system;
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                this.setErrorMessage(err.response?.data.message ?? '');
                console.log(`failed to login (${err.response?.data.code})`);
            }
        }

        return [];
    }

    @Mutation
    public setErrorMessage(msg: string): void {
        this.errorMessage = msg;
    }

    @Mutation
    public setSystemList(list: System[]): void {
        this.systemList = list;
    }

    @Mutation
    public setEncrypt(str: string): void {
        this.encryptParam = str;
    }
}

export const systemStore = getModule(SystemStore);
