
import Vue from 'vue';
import { Component, Emit, Prop } from 'vue-property-decorator';

@Component({
    name: 'v-button',
})
export default class VButton extends Vue {
    @Prop({ type: Boolean, required: false, default: () => false })
    readonly bottom!: boolean;
    @Prop({ type: Boolean, required: false, default: () => false })
    readonly left!: boolean;
    @Prop({ type: Boolean, required: false, default: () => false })
    readonly right!: boolean;
    @Prop({ type: Boolean, required: false, default: () => false })
    readonly top!: boolean;
    @Prop({ type: Boolean, required: false, default: () => false })
    readonly outlined!: boolean;

    @Prop({ type: Boolean }) readonly block!: boolean;
    @Prop({ type: String }) readonly color!: string;
    @Prop({ type: Boolean }) readonly disabled!: boolean;
    @Prop({ type: Boolean }) readonly icon!: boolean;
    @Prop({ type: Boolean }) readonly loading!: boolean;
    @Prop({ type: Boolean }) readonly round!: boolean;
    @Prop({ type: Boolean }) readonly text!: boolean;
    @Prop({ type: String }) readonly tooltip!: string;
    @Prop({ type: String }) readonly mdi!: string;
    @Prop({ type: String }) readonly word!: string;

    @Emit() onClick(): void {} // eslint-disable-line @typescript-eslint/no-empty-function

    clickButton(): void {
        this.onClick();
    }
}
