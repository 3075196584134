import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import store from '@s/store';
import { apiUrl } from '@l/api';
import { FailureResponse, post } from '@l/axios-helper';
import { Callback } from '@i/callback';
import { AxiosError } from 'axios';

@Module({ dynamic: true, store, name: 'callback', namespaced: true })
class CallbackStore extends VuexModule {
    errorMessage = '';
    callback: Callback = {};

    @Action({ commit: 'setCallback', rawError: true })
    public async search(args: { systemId: number; page: string; type?: string }): Promise<Callback> {
        try {
            const response = await post(`${apiUrl}/callback/search`, args);

            return response.data.data;
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                this.setErrorMessage(err.response?.data.message ?? '');
                console.log(`failed to search callback`);
            }
        }

        return {};
    }

    @Action({ rawError: true })
    public async exec(args: {
        systemId: number;
        page: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        url?: { [key: string]: any };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        header?: { [key: string]: any };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        body?: { [key: string]: any };
    }): Promise<void> {
        try {
            const response = await post(`${apiUrl}/callback/execute`, args);

            return response.data.data;
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                this.setErrorMessage(err.response?.data.message ?? '');
                console.log(`failed to exec callback`);
            }
        }

        return;
    }

    @Mutation
    public setErrorMessage(msg: string): void {
        this.errorMessage = msg;
    }

    @Mutation
    public setCallback(c: Callback): void {
        this.callback = c;
    }
}

export const callbackStore = getModule(CallbackStore);
