import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import { AddMember, EditMember, Member } from '@i/member';
import { System } from '@i/system';
import { apiUrl } from '@l/api';
import { delete_, FailureResponse, get, post, put } from '@l/axios-helper';
import store from '@s/store';
import { AxiosError } from 'axios';

const EDIT_MEMBER_INIT = {
    memberId: 0,
    memberName: '',
    systemIds: [],
};

@Module({ dynamic: true, store, name: 'member', namespaced: true })
class MemberStore extends VuexModule {
    errorMessage = '';
    memberList: Member[] = [];
    addMemberId = 0;
    editMember: EditMember = EDIT_MEMBER_INIT;
    searchMember = '';
    searchUser: number[] = [];
    page = '';

    @Action({ commit: 'setMemberList', rawError: true })
    public async getMember(): Promise<Member[]> {
        try {
            const response = await get(`${apiUrl}/member/all`);

            return response.data.data.member;
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                this.setErrorMessage(err.response?.data.message ?? '');
                console.log('failed to get member');
            }
        }

        return [];
    }

    @Action({ rawError: true })
    public async addMember(args: AddMember): Promise<number> {
        try {
            const response = await post(`${apiUrl}/member`, args);

            if (response.data.code === '0000') {
                return response.data.data.memberId;
            }
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                this.setErrorMessage(err.response?.data.message ?? '');
                console.log('failed to add member');
            }
        }

        return 0;
    }

    @Action({ rawError: true })
    public async addMemberForManager(args: AddMember): Promise<boolean> {
        try {
            const response = await post(`${apiUrl}/member/manager`, args);

            if (response.data.code === '0000') {
                this.setAddMemberId(response.data.data.memberId);
                return true;
            }
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                this.setErrorMessage(err.response?.data.message ?? '');
                console.log('failed to add member');
            }
        }

        return false;
    }

    @Action({ rawError: true })
    public async deleteMember(id: number): Promise<boolean> {
        try {
            const response = await delete_(`${apiUrl}/member/${id}`);

            return response.data.code === '0000' ? true : false;
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                this.setErrorMessage(err.response?.data.message ?? '');
                console.log('failed to delete member');
            }
        }

        return false;
    }

    @Action({ rawError: true })
    public async updateMember(args: {
        memberId: number;
        memberName: string;
        password: string;
        systemIds: number[] | null;
        userId?: number | null;
    }): Promise<boolean> {
        try {
            const response = await put(`${apiUrl}/member/${args.memberId}`, args);

            return response.data.code === '0000' ? true : false;
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                this.setErrorMessage(err.response?.data.message ?? '');
                console.log('failed to add member');
            }
        }

        return false;
    }

    @Action({ rawError: true })
    public async updateMemberForExternal(args: {
        memberId: number;
        memberName: string;
        password: string;
        roleId: number;
    }): Promise<boolean> {
        try {
            const role = args.roleId < 4 ? 'manager' : 'common';
            const response = await put(`${apiUrl}/member/${role}/${args.memberId}`, args);

            return response.data.code === '0000' ? true : false;
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                this.setErrorMessage(err.response?.data.message ?? '');
                console.log('failed to add member');
            }
        }

        return false;
    }

    @Action({ commit: 'setEditMember', rawError: true })
    public extractEditMember(args?: { id: number; system: System[] }): EditMember | null {
        if (typeof args === 'undefined') {
            return null;
        }

        const m: Member = this.memberList.filter((v: Member) => v.memberId === args.id)[0];
        const identifiers = m.identifiers;
        const systemIds: number[] = [];
        for (const k in identifiers) {
            const i = args.system.filter((v: System) => v.identifier === identifiers[k]);
            systemIds.push(i[0]['systemId']);
        }

        return {
            memberId: m.memberId,
            memberName: m.memberName,
            systemIds: systemIds,
            userId: m.userId,
        };
    }

    @Action({ commit: 'setPage', rawError: true })
    public setPageName(p: string | undefined | null): string {
        return p || '';
    }

    @Mutation
    public setErrorMessage(msg: string): void {
        this.errorMessage = msg;
    }

    @Mutation
    public setMemberList(list: Member[]): void {
        const idx = Object.keys(list).find((k: string) => list[+k].memberId === 999);
        if (idx !== undefined) list.splice(+idx, 1);
        this.memberList = list;
    }

    @Mutation
    public setEditMember(obj: EditMember): void {
        this.editMember = obj;
    }

    @Mutation
    public setAddMemberId(m: number): void {
        this.addMemberId = m;
    }

    @Mutation
    public setPage(p: string): void {
        this.page = p;
    }

    @Mutation
    public setSearchMember(s: string): void {
        this.searchMember = s;
    }

    @Mutation
    public setSearchUser(s: number[]): void {
        this.searchUser = s;
    }
}

export const memberStore = getModule(MemberStore);
