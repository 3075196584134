import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import ja from 'vuetify/src/locale/ja';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                // primary: '#00bcd4',
                // secondary: '#5c92bd',
                // accent: '#bdbdbd',
                // error: '#ff4081',
                // info: '#5c92bd',
                // success: '#4caf50',
                // warning: '#f1d54d'
                primary: '#4caf50',
                secondary: '#5c92bd',
                accent: '#bdbdbd',
                error: '#ff4081',
                info: '#5c92bd',
                success: '#00a273',
                warning: '#f1d54d',
            },
        },
    },
    icons: {
        iconfont: 'mdi',
    },
    lang: {
        locales: { ja },
        current: 'ja',
    },
});
