import { AxiosResponse } from 'axios';
import axios from '@l/axios-middleware';
import { stringify } from 'qs';

export interface FailureResponse {
    message: string;
    code: string;
}

export interface SuccessResponse<T> {
    data: T;
    code: '0000';
}

export type Response<T> = FailureResponse | SuccessResponse<T>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function get(u: string, data?: any): Promise<AxiosResponse<any>> {
    const url = data ? `${u}?${stringify(data)}` : u;

    return axios({
        method: 'get',
        url,
        data: stringify(data),
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function delete_(url: string, data?: any): Promise<AxiosResponse<any>> {
    return axios({
        method: 'delete',
        url,
        data: data,
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function put(url: string, data?: any): Promise<AxiosResponse<any>> {
    return axios({
        method: 'put',
        url,
        data: stringify(data),
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function post(url: string, data?: any): Promise<AxiosResponse<any>> {
    return axios({
        method: 'post',
        url,
        data: stringify(data),
    });
}
