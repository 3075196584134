import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';

import Cookies from 'js-cookie';
import { DateTime } from 'luxon';

import store from '@s/store';
import { apiUrl, domain } from '@l/api';
import { FailureResponse, get, post, put } from '@l/axios-helper';
import { Auth, ExternalParam, MemberInfo, TokenInfo } from '@i/auth';
import { EditMember } from '@i/member';
import { AxiosError } from 'axios';

const EDIT_MEMBER_INIT = {
    memberId: 0,
    memberName: '',
    systemIds: [],
};

@Module({ dynamic: true, store, name: 'auth', namespaced: true })
class AuthStore extends VuexModule {
    externalParam!: ExternalParam;
    errorMessage = '';
    isError = false;
    isLoggedIn = false;
    isOpened = true;
    editMember: EditMember = EDIT_MEMBER_INIT;
    private _accessToken = '';
    private _expiresIn = 0;
    private _memberId = 0;
    private _memberName = '';
    private _loginId = '';
    private _refreshToken = '';
    readonly specialToken =
        'v2.local.SA2qSdylYKav5jJbyL5-mJ9WZ5-N00A-C9Vn-7uaaEOBTnDyteSTPL618sLrUGpKN7t3r-dRKTvkDbs0uyWP5xdgldkUOR6BLArZOXUa6SKaTKyVi_LZWgLNY23T08pwzao6zQFth3mS_STxLDIvBDXcIFOcMnl4IO77m453rBaIADVpwLXeDzijvXZQr8UMEmNAmTK2CnnzewLn7rHWP2Iq8caRnr3i1dEd00iZX-pKGEmfbtA4DlFNzmxPnFj8uO9ijbqFdQ';

    private get cookieOption(): { expires: number; domain: string; secure: boolean } {
        return {
            expires: 7, // day
            domain,
            secure: true,
        };
    }

    public get accessToken(): string {
        const ct = Cookies.get('accessToken') || '';
        return this._accessToken === '' ? ct : this._accessToken;
    }

    public get refreshToken(): string {
        const rt = Cookies.get('refreshToken') || '';
        return this._refreshToken === '' ? rt : this._refreshToken;
    }

    public get expiresIn(): number {
        const now = new Date();
        const ei = Cookies.get('expiresIn') || now.getTime();
        return this._expiresIn === 0 ? +ei : this._expiresIn;
    }

    public get memberName(): string {
        const n = Cookies.get('memberName') || '';
        return this._memberName === '' ? n : this._memberName;
    }

    public get memberId(): number {
        const id = Cookies.get('memberId') || '';
        return this._memberId === 0 ? +id : this._memberId;
    }

    public get loginId(): string {
        const id = Cookies.get('loginId') || '';
        return this._loginId === '' ? id : this._loginId;
    }

    @Action({ rawError: true })
    public async checkLoginState(): Promise<boolean> {
        // 有効期限チェック
        const isAuthenticated = await this.checkValidity();

        // アクセストークン正当性チェック
        if (isAuthenticated) {
            return await this.validateAccessToken();
        }

        // トークン再交付
        return await this.refreshAccessToken();
    }

    @Action({ rawError: true })
    public async checkValidity(): Promise<boolean> {
        const now = DateTime.local().toMillis();
        if (this.accessToken && now < this.expiresIn) {
            this.SET_ACCESS_TOKEN(this.accessToken);
            return true;
        }

        return false;
    }

    @Action({ rawError: true })
    public async login(args: Auth): Promise<boolean> {
        try {
            const response = await post(`${apiUrl}/auth`, args);
            this.setLoginInfo(
                (
                    response.data as {
                        code: string;
                        data: MemberInfo & TokenInfo;
                    }
                ).data,
            );

            return true;
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                this.setErrorMessage(err.response?.data.message ?? '');
                console.log(`failed to login (${err.response?.data.code})`);
            }
        }

        return false;
    }

    @Action({ rawError: true })
    public async externalLogin(args: { externalId: string; jt: string }): Promise<boolean> {
        try {
            const response = await get(`${apiUrl}/auth/${args.externalId}/${args.jt}`);

            if (response.data.code !== '0000') {
                return false;
            }

            const m = response.data.data;
            this.setLoginInfo(m.token);
            this.SET_EDIT_MEMBER({
                memberId: m.memberId,
                memberName: m.memberName,
                systemIds: [m.systemId],
                groupIds: m.groupIds,
                registrantMemberId: m.registrantMemberId,
                registrantRoleId: m.registrantRoleId,
            });

            return true;
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                this.setErrorMessage(err.response?.data.message ?? '');
                console.log('failed to get member');
            }
        }

        return false;
    }

    @Action({ rawError: true })
    public logout(): void {
        this.SET_LOGIN_ID('');
        this.SET_MEMBER_ID(0);
        this.SET_MEMBER_NAME('');
        this.clearToken();
    }

    @Action({ rawError: true })
    public clearToken(): void {
        this.SET_ACCESS_TOKEN('');
        this.SET_EXPIRES_IN(0);
        this.SET_IS_LOGGED_IN(false);
        this.SET_REFRESH_TOKEN('');
    }

    @Action({ rawError: true })
    public async refreshAccessToken(): Promise<boolean> {
        try {
            if (!this.refreshToken) {
                throw new Error('no token');
            }

            this.SET_ACCESS_TOKEN(this.refreshToken);
            const response = await put(`${apiUrl}/auth`);
            this.setTokenInfo(response.data.data);

            return true;
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                console.log('refreshAccessToken:', err.response?.data.message);
            }
        }

        return false;
    }

    @Action({ rawError: true })
    public async validateAccessToken(): Promise<boolean> {
        try {
            await get(`${apiUrl}/auth`);
            this.SET_IS_LOGGED_IN(true);

            return true;
        } catch (e) {
            const err = e as AxiosError<FailureResponse>;
            if (err != null) {
                this.setErrorMessage(err.response?.data.message ?? '');
                console.log(`invalid token (${err.response?.data.code})`);
            }
        }

        return false;
    }

    @Action({ rawError: true })
    public setLoginInfo(data: MemberInfo & TokenInfo): void {
        this.SET_LOGIN_ID(data['loginId']);
        this.SET_MEMBER_ID(data['memberId']);
        this.SET_MEMBER_NAME(data['memberName']);
        this.setTokenInfo(data);
    }

    @Action({ rawError: true })
    public setTokenInfo(data: (MemberInfo & TokenInfo) | TokenInfo): void {
        this.SET_ACCESS_TOKEN(data['accessToken']);
        this.SET_EXPIRES_IN(DateTime.fromSQL(data['expiresIn']).toMillis());
        this.SET_IS_LOGGED_IN(true);
        this.SET_REFRESH_TOKEN(data['refreshToken']);
    }

    @Action({ commit: 'SET_ACCESS_TOKEN', rawError: true })
    public setAccessToken(token: string): string {
        return token;
    }

    @Action({ commit: 'SET_EXTERNAL_PARAM', rawError: true })
    public setExternalParam(param: ExternalParam): ExternalParam {
        return param;
    }

    @Action({ commit: 'SET_ERROR', rawError: true })
    public setErrorMessage(msg: string): string {
        return msg;
    }

    @Action({ commit: 'SET_IS_OPENED', rawError: true })
    public setIsOpened(f: boolean): boolean {
        return f;
    }

    @Mutation
    public SET_ACCESS_TOKEN(token: string): void {
        Cookies.set('accessToken', token, this.cookieOption);
        this._accessToken = token;
    }

    @Mutation
    public SET_EXTERNAL_PARAM(param: ExternalParam): void {
        this.externalParam = param;
    }

    @Mutation
    public SET_EXPIRES_IN(d: number): void {
        Cookies.set('expiresIn', '' + d, this.cookieOption);
        this._expiresIn = d;
    }

    @Mutation
    public SET_IS_LOGGED_IN(flag: boolean): void {
        this.isLoggedIn = flag;
    }

    @Mutation
    public SET_LOGIN_ID(id: string): void {
        Cookies.set('loginId', id, this.cookieOption);
        this._loginId = id;
    }

    @Mutation
    public SET_MEMBER_ID(id: number): void {
        Cookies.set('memberId', '' + id, this.cookieOption);
        this._memberId = id;
    }

    @Mutation
    public SET_MEMBER_NAME(name: string): void {
        Cookies.set('memberName', name, this.cookieOption);
        this._memberName = name;
    }

    @Mutation
    public SET_REFRESH_TOKEN(token: string): void {
        Cookies.set('refreshToken', token, this.cookieOption);
        this._refreshToken = token;
    }

    @Mutation
    public SET_ERROR(msg: string): void {
        this.errorMessage = msg;
        this.isError = true;
    }

    @Mutation
    public SET_IS_OPENED(f: boolean): void {
        this.isOpened = f;
    }

    @Mutation
    public SET_EDIT_MEMBER(m: EditMember): void {
        this.editMember = m;
    }
}

export const authStore = getModule(AuthStore);
